import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Header from './Header';
import StartPage from './StartPage'; // Import the new StartPage component
import Gallery1 from './Gallery1';
import Gallery2 from './Gallery2';
import './App.css';

function App() {
  return (
    
    <Router>
      <Header />
      <Routes>
      <Route path="/" element={<StartPage />} /> // Set StartPage as the root route
        <Route path="/Gallery1" element={<Gallery1 />} />
        <Route path="/Gallery2" element={<Gallery2 />} />
      </Routes>
    </Router>
  );
}

export default App;
