import React, { useState, useEffect } from 'react';
import './Gallery.css';
import ski from './ski.png';
import småbruk from './småbruk.png';
import dogs from './dogs.png';
import barn from './barn.png';
import hund from './hund.png';
import albin from './albin.png';
import zelda from './zelda.png';

function Gallery1() {
  const images = [småbruk, zelda, albin, dogs, barn, hund, småbruk, zelda, albin, dogs, barn]; // No need to duplicate the first image
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(currentIndex => {
        // Reset to 0 if we've reached the last image, otherwise increment
        return (currentIndex + 1) % images.length;
      });
    }, 2000); // Rotate images every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const getOffset = () => {
    const offset = -currentImageIndex * 100; // Assuming each image takes 100% of the gallery width
    return `translateX(${offset}%)`;
  };

  return (
    <div className="image-gallery">
      <div className="slider" style={{ transform: getOffset() }}>
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index}`} />
        ))}
      </div>
    </div>
  );
}

export default Gallery1;
