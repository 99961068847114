import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Gallery.css';

function Gallery2() {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const imageImports = [];
    for (let i = 1; i <= 98; i++) {
      imageImports.push(require(`./${i}.jpg`)); // Replace dynamic import with require for simplicity
    }
    setImages(imageImports);
  }, []);

  const switchToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const intervalId = setInterval(switchToNextImage, 1000);
    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <div className="carousel">
      {images.length > 0 && (
        <LazyLoadImage
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
          effect="blur"
          className="carousel-image"
          style={{
            width: 'auto',
            height: '600px',
            display: 'block',
            margin: '0 auto',
          }}
        />
      )}
    </div>
  );
}

export default Gallery2;
