import React from 'react';
import aspirationsImage from './aspirations.jpg'; // Import the image
import './StartPage.css';

function StartPage() {
  return (
    <div className="start-page">
      <div className="content-container">
        
        <img
          src={aspirationsImage} // Use the imported image
          alt="Aspirations"
          width="100%"
          height="350px"
          className="image-with-margin" // Add a class for styling
        />
        <iframe
          src="https://www.yr.no/en/content/1-211102/card.html?mode=dark"
          title="Weather in Trondheim"
          width="300px"
          height="350"
          allowTransparency="true"
          allow="encrypted-media"
        ></iframe>
        
      </div>
    </div>
    
  );
}

export default StartPage;
