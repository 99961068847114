import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header className="App-header">
      <div className="header-logo">
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 18"
          fill="#8A79DF"
          style={{ marginBottom: '0px' }}
        >
          <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
        </svg>
        <Link to="/" className="header-link">Heddaboard</Link>
      </div>
      <nav>
        <a href="https://libgen.is" className="header-link" target="_blank" rel="noopener noreferrer">Library Genesis</a>
        <a href="https://scholar.google.com" className="header-link" target="_blank" rel="noopener noreferrer">Google Scholar</a>
        <a href="https://chat.openai.com" className="header-link" target="_blank" rel="noopener noreferrer">ChatGPT</a>
        <a href="https://ntnu.blackboard.com/ultra/" className="header-link" target="_blank" rel="noopener noreferrer">Blackboard</a>
        <a href="https://www.overleaf.com" className="header-link" target="_blank" rel="noopener noreferrer">LaTeX</a>
        <a href="https://dusken.no" className="header-link" target="_blank" rel="noopener noreferrer">Under Dusken</a>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
<Link to="/Gallery1" className="header-link">Vår fremtid</Link>
<Link to="/Gallery2" className="header-link">Vår fortid</Link>

      </nav>
    </header>
  );
}

export default Header;
